import React, { useState } from "react";
import Layout from '../../components/layout/Layout'
import PreviewCompatibleImage from '../../components/image/PreviewCompatibleImage'
import CeramiqueBanner from "../../components/ceramique/CeramiqueBanner";
import ImageSlider from "../../components/image/ImageSlider";
import AtelierEnfantCollectionRoll from "../../components/atelier/AtelierEnfantCollectionRoll";
import { useIsMobile } from "../../hooks/useIsMobile";
import CeramiqueBannerWithChildren from "../../components/ceramique/CeramiqueBannerWithChildren";
import GiftCardBanner from "../../components/gift-card/GiftCardBanner";

const imageInfo1 = {
  "alt": "Des réalisations d'enfants lors d'ateliers a forma",
  "image": "/img/v2/Menfants-min.JPG",
  "maxWidth": 250
}

const imageInfo2 = {
  "alt": "Une enfant en pleine création ...",
  "image": "/img/v2/Nenfantspeinture-min.JPG",
  "maxWidth": 250
}

const AtelierEnfantsPage = () => {

  React.useEffect(() => {
  }, [])


  const {isMobile } = useIsMobile()


  return (
    <Layout>
      <section className="section">
        <div className="container">

          <div className="content">
            <div className="columns">
              <div className="column is-12">
                <div className="columns has-text-justified" style={{ textAlign: 'justify' }}>

                  <div className="column is-6">
                    <h3 className="title is-4">Déposez-nous vos enfants pour des moments créatifs !</h3>
                    <p></p>
                    <p>Un moment créatif animé par notre équipe pour faire découvrir à votre enfant le travail de la terre et lui permettre d'appréhender le volume en fabriquant de ses mains.
                      </p>
                      <p> Retrouvez à chaque vacances scolaires un atelier d'initiation au modelage avec Lisa, dès 6 ans sur inscription</p>

                    <div style={{ marginTop : isMobile ? "0" : "4rem"}}>
                      <GiftCardBanner/>
                    </div>

                  </div>

                  <div className="column has-text-centered is-3 is-justify-content-center is-flex">
                    <PreviewCompatibleImage imageInfo={imageInfo2} />
                  </div>

                  <div className="column has-text-centered is-3 is-justify-content-center is-flex">
                    <PreviewCompatibleImage imageInfo={imageInfo1} />
                  </div>
                </div>
              </div>
            </div>

            <div className="content is-offset-1" style={{marginTop: '1rem', marginBottom: '1rem'}}>

              <AtelierEnfantCollectionRoll/>
              <CeramiqueBannerWithChildren/>
            </div>
          </div>
        </div>
      </section>
    </Layout >
  )
}

export default AtelierEnfantsPage


